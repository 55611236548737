import { Country } from '@/util/Country.js'

export default {
  props: {
    value: {
      type: String,
    },
    countries: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'País de los destinatarios',
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'blue-grey lighten-5',
    },
  },
  data () {
    return {
      localCountryISO: this.value,
      Country: Country,
    }
  },
  methods: {
    countryFilter (item, queryText) {
      const cleanText = item.text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      return cleanText.indexOf(queryText) > -1 || item.text.toLowerCase().indexOf(queryText.toLowerCase()) > -1
   },
    isObject (item) {
      return typeof (item) !== 'object'
    },
    remove () {
      this.localCountryIso = null
    },
    emitChange () {
      setTimeout(() => {
        this.$emit('changed-country', this.localCountryISO)
      })
    },
  },
  watch: {
    localCountryISO (newCountryISO) {
      this.$emit('input', newCountryISO)
      this.emitChange()
    },
    value (newValue) {
      this.localCountryISO = newValue
    },
  },
  created () {
    this.emitChange()
  },
}
