var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":_vm.$t('País'),"rules":{
    required: _vm.required
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.countries,"disabled":_vm.disabled,"background-color":_vm.backgroundColor,"filled":"","dense":"","error-messages":errors,"filter":_vm.countryFilter,"label":_vm.$t(_vm.title),"item-text":"text","item-value":"value","rounded":_vm.rounded},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
  var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-1",attrs:{"left":"","size":"20"}},[_c('img',{style:({ opacity: item.disabled ? 0.4 : 1 }),attrs:{"rounded":"0","width":"20","height":"20","src":_vm.Country.getFlag(item.value)}})]),_c('span',{staticClass:"selected-item",style:({ opacity: item.disabled ? 0.4 : 1 })},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"item",fn:function(ref){
  var item = ref.item;
return [(_vm.isObject(item))?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(item)}})]:[_c('v-list-item-avatar',{attrs:{"rounded":"0","width":"30","height":"30"}},[_c('img',{style:({ opacity: item.disabled ? 0.4 : 1 }),attrs:{"src":_vm.Country.getFlag(item.value)}})]),_c('v-list-item-content',[_c('v-list-item-title',{style:({ opacity: item.disabled ? 0.4 : 1 }),domProps:{"innerHTML":_vm._s(item.text)}})],1)]]}}],null,true),model:{value:(_vm.localCountryISO),callback:function ($$v) {_vm.localCountryISO=$$v},expression:"localCountryISO"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }