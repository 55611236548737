/* eslint-disable no-case-declarations */
import SessionsService from '@/services/whatsapp/sessions.service.js'
import ChatWindow from '@/components/vue-chat/lib/ChatWindow.vue'
import RoutesService from '@/services/whatsapp/routes.service'
import ContactNoteService from '@/services/whatsapp/contact-notes.service'
import SvgIcon from '@/components/vue-chat/components/SvgIcon/SvgIcon'
import EventBus from '@/util/EventBus.js'
import ContactEditDialog from '@/views/Pages/Developers/ChannelIntegration/WhatsAppChannel/Contacts/ContactEditDialog.vue'
import { Contact } from '@/models/whatsapp/Contact'

import { TextMessage } from '@/classes/chat/text-message.js'
import { ClosedMessage } from '@/classes/chat/closed-message.js'
import { OpenedMessage } from '@/classes/chat/opened-message.js'
import { LocationMessage } from '@/classes/chat/location-message.js'
import { MultimediaMessage } from '@/classes/chat/multimedia-message.js'
import { ContactEditionMessage } from '@/classes/chat/contact-edition-message.js'
import { ContactNoteMessage } from '@/classes/chat/contact-note-message.js'
import { ContactBlockMessage } from '@/classes/chat/contact-block-message.js'
import { ChangeAgentMessage } from '@/classes/chat/change-agent-message.js'
import { UnsupportedMessage } from '@/classes/chat/unsupported-message.js'

import { ButtonMessage } from '@/classes/chat/button-message'

import { Message as CommonMessage } from '@/classes/chat/send-message/message.js'
import { TextMessage as SendTextMessage } from '@/classes/chat/send-message/text-message.js'
import { LocationMessage as SendLocationMessage } from '@/classes/chat/send-message/location-message.js'
import { TemplateMessage as SendTemplateMessage } from '@/classes/chat/send-message/template.js'
import { MultimediaMessage as SendMultimediaMessage } from '@/classes/chat/send-message/multimedia-message.js'
import { InteractiveMessage as SendInteractiveMessage } from '@/classes/chat/send-message/interactive-message.js'
import { TemplateMessage } from '@/classes/chat/template-message'
import { InteractiveMessage } from '@/classes/chat/interactive-message'
import { InteractiveAnswerMessage } from '@/classes/chat/interactive-answer-message'
import getEnv from '@/util/env'
import HeadboardOption from '@/models/whatsapp-editor/HeadBoardOption'
import UserInboxTagServiceService from '@/services/whatsapp/user-inbox-tag-service.service'
import MultimediaService from '@/services/multimedia.service'
import WhatsappMessageService from '@/services/whatsapp-message-service'
import { BlocklistType } from '@/models/whatsapp/BlocklistType'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import RoomBlocklist from '@/components/vue-chat/lib/Room/RoomHeader/RoomBlocklist/RoomBlocklist.vue'
import CloseConversation from '@/components/vue-chat/lib/CloseConversation/CloseConversation.vue'
import { ChatProps } from '@/classes/chat/chat-props.js'
import AiService from '@/services/ai/ai.service.js'
import { Agent } from '@/classes/chat/agent'
import ContactsService from '@/services/whatsapp/contacts.service'
import { CALL_TO_ACTION_TYPE } from '@/models/whatsapp-editor/enums/CallToActionType'
import HeadboardType from '@/models/whatsapp-editor/HeadboardType'
import { MESSAGE_INTERACTIVE_TYPES } from '@/models/whatsapp/enums/MessageInteractiveType'
import { MESSAGE_INTERACTIVE_HEADER } from '@/models/whatsapp/enums/MessageInteractiveHeaderType'
import ModelMessageInteractive from '@/models/whatsapp/MessageInteractive'
import MessageInteractive from '@/components/Whatsapp/MessageInteractive/MessageInteractive.vue'
import MessageInteractivePreview from '@/components/Whatsapp/MessageInteractivePreview/MessageInteractivePreview.vue'
import { TextUtils } from '@/util/TextUtils'

const { io } = require('socket.io-client')

export default {
  name: 'ChatPage',
  props: {
    routes: {
      type: Object,
      required: true,
      default: () => ({
        list: [],
      }),
    },
  },
  components: {
    ChatWindow,
    SvgIcon,
    ContactEditDialog,
    RoomBlocklist,
    CloseConversation,
    MessageInteractive,
    MessageInteractivePreview,
  },
  data: function () {
    return {
      MESSAGES_PER_LOAD: 10,
      socket: null,
      roomsLoaded: false,
      loadingRooms: false,
      messagesLoaded: false,
      currentUserMsisdn: '',
      contactMsisdn: '',
      currentRoom: null,
      allRooms: [],
      rooms: [],
      messages: [],
      roomActions: [
        { name: 'closeConversation', title: this.$t('Cerrar la conversación'), icon: 'fas fa-box-archive' },
        { name: 'addToBlackList', title: this.$t('Bloquear contacto'), icon: 'fas fa-fas fa-user-slash' },
        { name: 'toggleRead', title: this.$t('Marcar como leído'), icon: 'fas fa-fas fa-comment' },
        { name: 'addNote', title: this.$t('Agregar nota'), icon: 'fas fa-file-medical' },
        { name: 'fag', title: this.$t('Etiquetar'), icon: 'fas fa-tag' },
      ],
      roomFilters: {
        opened: true,
        closed: false,
        pending: false,
      },
      showFooter: true,
      roomsSource: [],
      locationDialog: false,
      messageInteractiveDialog: false,
      noteDialog: false,
      contactNote: {
        show: false,
        loading: false,
        id: null,
        annotation: '',
        mode: ['create', 'update', 'list'],
        modeSelected: 'create',
      },
      sendLocationMessageObject: new SendLocationMessage(),
      sendTemplateMessageObject: new SendTemplateMessage(),
      selectedTemplate: null,
      showReplaceVarsDialog: false,
      editContactDialog: false,
      contact: new Contact(),
      HeadboardOption: HeadboardOption.options,
      tags: [],
      calledMultimedia: false,
      user: this.$store.getters['auth/getUser'],
      currentUserRoutes: [],
      // routes: {
      //   list: [],
      // },
      dialogConfirmBlacklist: false,
      roomInAction: null,
      dialogConfirmCloseConversation: false,
      windowHeight: '780px',
      fullscreenHeight: '100%',
      height: this.windowHeight,
      chatProps: new ChatProps(),
      contactDefinitiveBlocklist: {
        list: [],
      },
      blocklistStatus: [],
      selectedUserRoute: null,
      eventUuidContactDialog: '',
      items: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
      searchTerm: '',
      notes: [],
      currentAgent: { id: null, typeId: null },
      makeResquestChangeToAssistant: false,
      CALL_TO_ACTION_TYPE: CALL_TO_ACTION_TYPE,
      modelMessageInteractive: new ModelMessageInteractive(),
      sendInteractiveMessageObject: new SendInteractiveMessage(),
      focusedRoom: undefined,
      task: undefined,
      fieldsAsArray: [],
      contactPreview: null,
    }
  },
  unmounted () {
    EventBus.$off('multimediaSelected', this.loadMultimediaForTemplate)
    EventBus.$off('Whatsapp:toggleConversationRead', this.toggleAsRead)
  },
  beforeRouteLeave (to, from, next) {
    if (this.socket) {
      this.socket.disconnect()
    }
  },
  created () {
    if (this.routes.length) {
      EventBus.$emit('showLoading', true, this.$t('Cargando datos...'))
    }
    // EventBus.$on('whatsapp-inbox-update-conversarions', this.getRooms)
    // EventBus.$on('hook:beforeDestroy', () => {
    //   EventBus.$off('whatsapp-inbox-update-conversarions', this.getRooms)
    // })

    EventBus.$on('chatWindow:toggleFullscreen', fullscreen => {
      this.height = fullscreen ? this.fullscreenHeight : this.windowHeight
    })
  },
  mounted () {
    this.eventUuidContactDialog = 'event-uuid-contact-dialog-' + uuidv4()

    EventBus.$on('ChangeCurrentUserMsisdn', (msisdn) => {
      EventBus.$emit('hideSessionTimeExpiredAlert')
      this.changeSender(msisdn)
      this.allTags(msisdn)
      this.messages = []
    })

    EventBus.$on('multimediaSelected', this.loadMultimediaForTemplate)
    EventBus.$on('ContactEditDialog:updateContact', this.updateContact)
    EventBus.$on('Whatsapp:addChangeAgentMessage', this.addChangeAgentMessage)
    EventBus.$on('Chat:OpenContactDialog', () => {
      this.roomInfo(this.currentRoom)
      setTimeout(() => {
        document.getElementById('tagsContact').click()
      }, 500)
    })
    EventBus.$on('Whatsapp:toggleConversationRead', this.toggleAsRead)

    const websocketUrl = getEnv('backend_websocket_url')
    const user = JSON.parse(localStorage.getItem('user'))
    if (!this.socket) {
      this.socket = io(websocketUrl, {
        transports: ['websocket'],
        auth: {
          userId: user.id,
          token: user.token,
          service: 'WHATSAPP',
        },
      })
    }

    this.socket.on('message', (message) => {
      switch (message.type) {
        case 'WHATSAPP_MESSAGE_SENT':
          this.messageSentMessage(message)
          EventBus.$emit('Whatsapp:reorderRooms')
          return
        case 'WHATSAPP_MESSAGE_STATUS_CHANGE':
          this.messageStatusChange(message)
          return
        case 'WHATSAPP_AUTO_MESSAGE':
          this.messageAutoMessage(message)
          EventBus.$emit('Whatsapp:reorderRooms')
          return
      }

      const newMessage = this.loadMessage(message, false)

      if (message.type === 'reaction') {
        this.addReactionToMessage(message)
      }
      const msisdnClient = this.currentRoom?.users[1].msisdnClient

      const room = this.findRoom(message.msisdn_user, message.msisdn_client)
      if (msisdnClient === newMessage.senderId) {
        this.addMessage(newMessage)
        this.setLastCSWTime()
        EventBus.$emit('templateMessage:recentlySendTemplate', false)
      }
      this.increaseRoomCounter(room)

      if (room.users[1].contact.status === 0) {
        this.openConversation({ room })
      }
      room.messagesNumberClient++

      room.lastMessage = {
        timestamp: moment(message.created_at),
        content: this.getLastMessageContent(message),
        type: message.type,
        status: message.contact.status,
        lastAction: 'message',
      }

      this.updateConversationLastMessageDate(room, message.created_at)

      EventBus.$emit('Whatsapp:enableSendMessage')
      EventBus.$emit('Whatsapp:reorderRooms')
    })

    this.socket.on('connect', () => {
      // websocketsService.updateUserWebsocketId({
      //   websocketId: this.socket.id,
      // })
      // .then(result => {
      // })
    })

    this.socket.on('disconnect', () => {
      // update socket id = null
      // websocketsService.deleteUserWebsocketId({
      //   websocketId: this.socket.id,
      // })
      // .then(result => {
      // })
    })

    this.getCurrentUserRoutes()
    AiService.getAssistantsSimpleList().then((res) => {
      res.forEach(agent => {
        const agentObject = new Agent(agent)
        this.chatProps.agents.push(agentObject)
      })
    })

    const task = JSON.parse(localStorage.getItem('load-conversation-from-task'))

    if (task) {
      EventBus.$emit('showLoading', true)
      this.task = task
      SessionsService.getConversationFromMessageId({ id: task.base_message_id }).then((res) => {
        this.focusedRoom = this.processConversation(res[0])
        this.filterChats()
      })
    }
  },
  watch: {
    'modelMessageInteractive.body': function (value) {
      const result = TextUtils.formatTextIfEmojiAndFormatPresent(value)
      if (result) {
        setTimeout(() => {
          this.modelMessageInteractive.body = result
        })
      }
    },

    params: {
      handler () {
        this.getNotes()
      },
      deep: true,
    },
    currentUserMsisdn: function (newValue, oldValue) {
      this.selectedUserRoute = this.currentUserRoutes.find(r => r.msisdn === newValue) ?? this.currentUserRoutes[0]
    },
    latitude: function (newValue, oldValue) {
      this.$nextTick(() => {
        if (this.sendLocationMessageObject.latitude) {
          if (parseFloat(newValue) > 90) {
            this.sendLocationMessageObject.latitude = 90
          } else if (parseFloat(newValue) < -90) {
            this.sendLocationMessageObject.latitude = -90
          }
        }
      })
    },
    longitude: function (newValue, oldValue) {
      this.$nextTick(() => {
        if (this.sendLocationMessageObject.longitude) {
          if (parseFloat(newValue) > 180) {
            this.sendLocationMessageObject.longitude = 180
          } else if (parseFloat(newValue) < -180) {
            this.sendLocationMessageObject.longitude = -180
          }
        }
      })
    },
    templateLatitude: function (newValue, oldValue) {
      this.$nextTick(() => {
        if (this.sendTemplateMessageObject.substitutions.header.latitude) {
          if (parseFloat(newValue) > 90) {
            this.sendTemplateMessageObject.substitutions.header.latitude = 90
          } else if (parseFloat(newValue) < -90) {
            this.sendTemplateMessageObject.substitutions.header.latitude = -90
          }
        }
      })
    },
    templateLongitude: function (newValue, oldValue) {
      this.$nextTick(() => {
        if (this.sendTemplateMessageObject.substitutions.header.longitude) {
          if (parseFloat(newValue) > 180) {
            this.sendTemplateMessageObject.substitutions.header.longitude = 180
          } else if (parseFloat(newValue) < -180) {
            this.sendTemplateMessageObject.substitutions.header.longitude = -180
          }
        }
      })
    },
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('Agente'),
          value: 'contact',
          sortable: false,
        },
        {
          text: this.$t('Texto'),
          value: 'annotation',
          sortable: false,
        },
        {
          text: this.$t('Fecha de creación'),
          value: 'created_at',
          sortable: false,
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    params (nv) {
      return {
        ...this.options,
      }
    },
    latitude () {
      return this.sendLocationMessageObject.latitude
    },
    longitude () {
      return this.sendLocationMessageObject.longitude
    },
    templateLatitude () {
      return this.sendTemplateMessageObject.substitutions.header.latitude
    },
    templateLongitude () {
      return this.sendTemplateMessageObject.substitutions.header.longitude
    },
    getContactFields () {
      return [
        { value: 'firstName', text: this.$t('Nombre') },
        { value: 'lastName', text: this.$t('Apellidos') },
        { value: 'email', text: this.$t('Email') },
        { value: 'msisdnClient', text: this.$t('Número de telefono') },
      ]
    },
     headerTextMessageHelper () {
      return this.getCleanHeaderTextMessageSubstitution(false)
    },
    messageHelper () {
      return this.getCleanMessageSubstitutions(false)
    },
    callToActionResponses () {
      return this.selectedTemplate?.call_to_action_responses.length
        ? JSON.parse(this.selectedTemplate.call_to_action_responses)
        : []
    },
    hasFile () {
      return [
        HeadboardOption.options.IMAGE,
        HeadboardOption.options.VIDEO,
        HeadboardOption.options.DOCUMENT,
      ].includes(this.selectedTemplate.head_board_option)
    },
    callToActionUrlHelper () {
      return (index) => this.getCleanCallToActionUrlHelper(index, false)
    },
    isButtonEmpty () {
      if (
        this.modelMessageInteractive.type === MESSAGE_INTERACTIVE_TYPES.BUTTON &&
        this.modelMessageInteractive.action.buttons.length === 0
      ) {
        return true
      }

      return false
    },
    isListEmpty () {
      if (
        this.modelMessageInteractive.type === MESSAGE_INTERACTIVE_TYPES.LIST &&
        this.modelMessageInteractive.action.sections.length === 0
      ) {
        return true
      }

      return false
    },
    isImageHeader () {
      return this.modelMessageInteractive.header.type === MESSAGE_INTERACTIVE_HEADER.IMAGE
    },
    isTextHeader () {
      return this.modelMessageInteractive.header.type === MESSAGE_INTERACTIVE_HEADER.TEXT
    },
    isVideoHeader () {
      return this.modelMessageInteractive.header.type === MESSAGE_INTERACTIVE_HEADER.VIDEO
    },
    isDocumentHeader () {
      return this.modelMessageInteractive.header.type === MESSAGE_INTERACTIVE_HEADER.DOCUMENT
    },
    messageInteractiveHeaderIsEmpty () {
      let response = true
      if ((this.modelMessageInteractive.header.visible) &&
        (this.isImageHeader || this.isVideoHeader || this.isDocumentHeader)) {
        if (this.modelMessageInteractive.header.url === '') {
          response = false
        }
      }

      return response
    },
    messageInteractiveHeaderTextIsEmpty () {
      let response = true
      if ((this.modelMessageInteractive.header.visible) &&
        (this.isTextHeader)) {
        if (this.modelMessageInteractive.header.content === '') {
          response = false
        }
      }

      return response
    },
    templateHasHeader () {
      return [
          HeadboardType.headboardTypes.HEADER_TEXT,
          HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
          HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
          HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ].includes(this.selectedTemplate.head_board_type)
  },
  },
  methods: {
    changeSender (msisdn) {
      this.getRooms(msisdn)
      this.$emit('change-user-msisdn', msisdn)
    },
    closeDialogBlocklist () {
      this.dialogConfirmBlacklist = false
    },
    roomActionHandler ({ roomId, action }) {
      console.log('roomId: ', roomId)
      console.log('this.rooms: ', this.rooms)
      const foundRoom = this.rooms.filter((item) => item.roomId === roomId)
      console.log('foundRoom: ', foundRoom)
      this.roomInAction = foundRoom[0]
      this.currentRoom = this.roomInAction
      switch (action.name) {
        case 'closeConversation':
          this.dialogConfirmCloseConversation = true
          break
        case 'addToBlackList':
          SessionsService.getBlockListFromMsisdnUserAndClient({
            msisdnClient: this.roomInAction.users[1].contact.msisdnClient,
            msisdnUser: this.roomInAction.users[1].contact.msisdnUser,
          }).then((contactBlocklist) => {
            this.contactDefinitiveBlocklist.list = contactBlocklist
            this.blocklistStatus = this.roomInAction.blocklist_status ? [...this.roomInAction.blocklist_status] : []
            this.dialogConfirmBlacklist = true
          })
          break
        case 'toggleRead':
          if (this.isContactUnread(this.roomInAction)) {
            this.markAsRead({ room: this.roomInAction })
          } else {
            this.markAsUnread({ room: this.roomInAction })
          }
          break
        case 'addNote':
          this.contactNote.show = true
          break
        case 'fag':
          EventBus.$emit('Chat:OpenContactDialog')
          break
      }
    },
    isContactUnread (room) {
      return !room.users[1].contact.read
    },
    setContactRead (room) {
      room.users[1].contact.read = 1
      room.status = {
        opened: !room.status.closed,
        closed: room.status.closed,
        pending: false,
      }
    },
    getLastMessageContent (message) {
      let content = ''
      switch (message.type) {
        case 'text':
          content = message.text_message.body.replace(TextMessage.REPLACE_REGEX, ' ')
          break
        case 'button':
          content = message.callback_message.text.replace(ButtonMessage.REPLACE_REGEX, ' ')
          break
      }
      return content
    },
    allTags (msisdn) {
      UserInboxTagServiceService.allTags({ msisdn })
      .then((response) => {
        this.tags = [...response]
      })
    },

    addMessage (newMessage) {
      if (this.messages.map(m => m._id).includes(newMessage._id)) {
        return
      }
      this.messages.push(newMessage)
      this.messages = this.messages.sort(function (m1, m2) {
        if (m1.dateTime < m2.dateTime) {
          return -1
        }
        if (m1.dateTime > m2.dateTime) {
          return 1
        }
        return 0
      })
    },
    messageSentMessage (message) {
      message.message.msisdn_from = message.message.msisdn
      // message.message.msisdn = message.message.msisdn_to
      const newMessage = this.loadMessage(message.message, false)
      const msisdnClient = this.currentRoom.users[1].msisdnClient

      if (msisdnClient === message.message.msisdn_to) {
        this.addMessage(newMessage)
        this.setLastCSWTime()
      }

      const room = this.findRoom(message.message.msisdn_user, message.message.msisdn_client)
      if (room) {
        if (room.users[1].contact.status === 0) {
          this.openConversation({ room })
        }
        room.unreadCount = 0
        this.setContactRead(room)

        room.lastMessage = {
          timestamp: moment(message.message.created_at),
          content: this.getLastMessageContent(message.message),
          type: message.message.type,
          status: 1,
          lastAction: 'message',
        }

        this.updateConversationLastMessageDate(room, message.message.created_at)
      }
    },
    messageAutoMessage (message) {
      message.message.msisdn_from = message.message.msisdn
      // message.message.msisdn = message.message.msisdn_to
      const newMessage = this.loadMessage(message.message, false)
      const msisdnClient = this.currentRoom.users[1].msisdnClient

      if (msisdnClient === message.message.msisdn_to) {
        this.addMessage(newMessage)
        this.setLastCSWTime()
      }

      const room = this.findRoom(message.message.msisdn_user, message.message.msisdn_client)
      if (room) {
        if (room.users[1].contact.status === 0) {
          this.openConversation({ room })
        }
        room.unreadCount = 0
        this.setContactRead(room)

        room.lastMessage = {
          timestamp: moment(message.message.created_at),
          content: this.getLastMessageContent(message.message),
          type: message.message.type,
          status: 1,
          lastAction: 'message',
        }

        this.updateConversationLastMessageDate(room, message.message.created_at)
      }
    },
    updateConversationLastMessageDate (room, date) {
      room.dateLastMessage = date || moment().format('YYYY-MM-DD HH:mm:ss')
    },
    messageStatusChange (message) {
      const index = this.messages.findIndex(messageObj => {
        return messageObj._id === message.message_id
      })

      if (this.messages[index]) {
        switch (message.new_status) {
          case 'SENT':
            this.messages[index].saved = true
            break
          case 'DELIVERED':
            this.messages[index].distributed = true
            break
          case 'READ':
            this.messages[index].setToRead()
            break
        }
      }
    },
    loadMultimediaForTemplate (url, type, fileId, nameTemplate, fileInfo) {
      if (this.calledMultimedia) {
        this.selectFile(url, fileInfo)
        EventBus.$emit('enableAttachFromFooter')
        this.calledMultimedia = false
      }
    },
    findRoom (msisdnUser, msisdnClient) {
      return this.allRooms.find((room) => room.users[1].contact.msisdnClient === msisdnClient && room.users[1].contact.msisdnUser === msisdnUser)
    },
    increaseRoomCounter (room) {
      room.unreadCount++
      room.users[1].contact.read = 0
    },
    updateContact (data) {
      if (data.eventUuid !== this.eventUuidContactDialog) return
      const contact = data.contact
      this.currentRoom.users[1].contact = contact
      this.contact.loadData(this.currentRoom.users[1].contact)
      this.currentRoom.roomName = (contact.firstName ?? '') + ' ' + (contact.lastName ?? '')
      this.currentRoom.lastMessage.lastAction = 'edit'
      this.currentRoom.lastMessage.content = this.$t('Editado')
      this.addContactEditedMessage()
    },
    roomInfo (room) {
      this.contact.loadData(room.users[1].contact)
      EventBus.$emit('ContactEditDialog:show', {
        eventUuid: this.eventUuidContactDialog,
        contact: this.contact,
        routes: this.routes,
        selectedUserMsisdn: this.currentUserMsisdn,
      })
    },

    openFile ({ message, file }) {
      this.downloadFile(file.file.url, file.file.name)
    },
    async downloadFile (url, filename) {
      try {
        // Fetch the file
        const response = await fetch(url)

        // Check if the request was successful
        if (response.status !== 200) {
          throw new Error(`Unable to download file. HTTP status: ${response.status}`)
        }

        // Get the Blob data
        const blob = await response.blob()

        // Create a download link
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(blob)
        downloadLink.download = filename

        // Trigger the download
        document.body.appendChild(downloadLink)
        downloadLink.click()

        // Clean up
        setTimeout(() => {
          URL.revokeObjectURL(downloadLink.href)
          document.body.removeChild(downloadLink)
        }, 100)
      } catch (error) {
      }
    },
    assignContactValueToBody (key, index) {
      this.sendTemplateMessageObject.substitutions.body[index] = '' + this.currentRoom.users[1].contact[key].substring(0, 60)
      this.$refs['bodyTextField' + index][0].focus()
      this.$refs['bodyTextField' + index][0].blur()
    },
    assignContactValueToButtons (key, index) {
      this.sendTemplateMessageObject.substitutions.buttons[index] = '' + this.currentRoom.users[1].contact[key].substring(0, 60)
      this.$refs['callToActionTextField' + index][0].focus()
      this.$refs['callToActionTextField' + index][0].blur()
    },
    assignContactValueToHeader (key) {
      this.sendTemplateMessageObject.substitutions.header = '' + this.currentRoom.users[1].contact[key].substring(0, 60)
    },
    async selectFile (url, fileInfo) {
      const [simpleType, tooBig, sizeTooBig] = HeadboardOption.fileType(fileInfo)

      if (simpleType === 'audio') {
        // Translate wav to mp3
        await MultimediaService.getMp3FromAudio({
          filename: fileInfo.name + '.' + fileInfo.extension,
        })
          .then(response => {
            url = response.url
            fileInfo = response.fileInfo
          })
      }

      if (tooBig) {
        EventBus.$emit('showAlert', 'danger', this.$t('Has elegido un fichero demasiado grande. El tamaño máximo para este tipo de fichero es ') + sizeTooBig + 'MB.')
      } else {
        if (!simpleType?.length) {
          EventBus.$emit('showAlert', 'danger', this.$t('Este tipo de fichero no puede ser enviado a través de WhatsApp'))
        } else {
          this.sendTemplateMessageObject.substitutions.header = {
            url: `${getEnv('backend_assets_url')}${url}`,
            mimeType: fileInfo.mimeType,
          }
        }
      }
    },
    showMultimedia (currentRootDirectory) {
      EventBus.$emit('disableAttachFromFooter')
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
      this.calledMultimedia = true
    },
    getCleanHeaderTextMessageSubstitution (clean = true, template, sendTemplateMessageObjectHelper) {
      template = template || this.selectedTemplate
      sendTemplateMessageObjectHelper = sendTemplateMessageObjectHelper || this.sendTemplateMessageObject
      if (!template.head_board || typeof template.head_board === 'object') return ''
      return template.head_board.replace(/\{COL\+}/g, (match) => {
        let substitution = match
        if (sendTemplateMessageObjectHelper.substitutions.header.length > 0) {
          substitution = sendTemplateMessageObjectHelper.substitutions.header
        }
        return clean ? substitution : '<b>' + substitution + '</b>'
      })
    },
    getCleanMessageSubstitutions (clean = true, template, sendTemplateMessageObjectHelper) {
      template = template || this.selectedTemplate
      sendTemplateMessageObjectHelper = sendTemplateMessageObjectHelper || this.sendTemplateMessageObject
      let idx = 0
      return template.message?.replace(/\{COL\+}/g, (match) => {
        let substitution = match
        if (sendTemplateMessageObjectHelper.substitutions.body.length > 0) {
          substitution = sendTemplateMessageObjectHelper.substitutions.body[idx]?.length > 0 ? sendTemplateMessageObjectHelper.substitutions.body[idx] : substitution
        }
        idx++
        return clean ? substitution : '<b>' + substitution + '</b>'
      })
    },
    getCleanCallToActionUrlHelper (index, clean = true, template, sendTemplateMessageObjectHelper) {
      template = template || this.selectedTemplate
      sendTemplateMessageObjectHelper = sendTemplateMessageObjectHelper || this.sendTemplateMessageObject
      return this.callToActionResponses[index]?.url?.replace(/\{COL\+}/g, (match) => {
        let substitution = match
        if (sendTemplateMessageObjectHelper.substitutions?.buttons?.length > 0) {
          substitution = sendTemplateMessageObjectHelper.substitutions.buttons[index]
        }
        return clean ? substitution : '<b>' + substitution + '</b>'
      })
    },
    textHasVars (text) {
      return text ? text.split('{COL+}').length - 1 > 0 : false
    },
    textCountVars (text) {
      return text ? text.split('{COL+}').length - 1 : 0
    },

    checkShowValidTemplate (template) {
      this.selectedTemplate = template
      const commonMessage = CommonMessage.getNewCurrentCommonMessage(this.currentUserMsisdn, this.contactMsisdn)

      this.sendTemplateMessageObject = new SendTemplateMessage(commonMessage, this.selectedTemplate.id)

      if (
          [
            HeadboardType.headboardTypes.HEADER_TEXT,
            HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
            HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
            HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
          ].includes(this.selectedTemplate.head_board_type)) {
        if (
            [
              HeadboardOption.options.IMAGE,
              HeadboardOption.options.VIDEO,
              HeadboardOption.options.DOCUMENT,
            ].includes(this.selectedTemplate.head_board_option)) {
          this.sendTemplateMessageObject.substitutions.header = this.selectedTemplate.head_board
        }

        if (this.selectedTemplate.head_board_option === HeadboardOption.options.LOCATION) {
          this.sendTemplateMessageObject.substitutions.header = {
            businessName: null,
            businessAddress: null,
            latitude: null,
            longitude: null,
          }
        }
      }

      if (this.textHasVars(this.selectedTemplate.message)) {
        this.sendTemplateMessageObject.substitutions.body = Array.from({ length: this.textCountVars(this.selectedTemplate.message) }, () => '')
      }
      this.showReplaceVarsDialog = true
    },
    showLocationDialog () {
      this.locationDialog = true
      const commonMessage = CommonMessage.getNewCurrentCommonMessage(this.currentUserMsisdn, this.contactMsisdn)
      this.sendLocationMessageObject = new SendLocationMessage(commonMessage)
    },
    showMessageInteractiveDialog () {
      this.modelMessageInteractive = new ModelMessageInteractive()
      const contact = this.currentRoom.users[1].contact
      this.contactPreview = contact
      this.fieldsAsArray = [
        { value: 'msisdnClient', text: this.$t('Teléfono').toString() },
        { value: 'firstName', text: this.$t('Nombres').toString() },
        { value: 'lastName', text: this.$t('Apellidos').toString() },
        { value: 'email', text: this.$t('Email').toString() },
        { value: 'interest', text: this.$t('Intereses').toString() },
        { value: 'comment', text: this.$t('Comentario').toString() },
        { value: 'countryIso', text: this.$t('País').toString() },
      ]
      this.messageInteractiveDialog = true
    },

    showNoteDialog (type) {
      this.contactNote.modeSelected = 'create'
      this.contactNote.show = true
    },
    showNoteDialogList () {
      this.contactNote.modeSelected = 'list'
      this.contactNote.show = true
    },
    getParams () {
      const params = {
        page: this.options.page ?? this.page,
        per_page: this.options.itemsPerPage ?? this.itemsPerPage,
        contact_id: this.currentRoom.users[1].contact.id,
      }
      return params
    },
    getNotes () {
      this.notes = []
      const data = this.getParams()
      this.contactNote.modeSelected = 'list'
      this.disabledPagination = true
      ContactNoteService.findAll(data)
        .then(
          (response) => {
            this.notes = response.data.data
            this.total = response.data.total
            this.pageCount = response.data.last_page
            this.page = response.data.current_page
            this.disabledPagination = false
          },
          () => {
            EventBus.$emit('showAlert', 'danger', this.$t('Hubo un problema al intentar ver las notas'))
          },
        )
    },
    addNote () {
      this.contactNote.modeSelected = 'create'
      this.contactNote.annotation = ''
    },
    viewNote (item) {
      this.contactNote.modeSelected = 'update'
      this.contactNote.id = item.id
      this.contactNote.annotation = item.annotation
      this.contactNote.contact_id = item.contact_id
    },
    createNote () {
      this.contactNote.modeSelected = 'create'
      this.contactNote.loading = true
      ContactNoteService.create({
        annotation: this.contactNote.annotation,
        contact_id: this.currentRoom.users[1].contact.id,
      })
        .then(
          () => {
            this.addNoteEditedMessage(this.contactNote.annotation)
            this.getNotes()
            this.contactNote.annotation = ''
            this.contactNote.show = false
            EventBus.$emit('showAlert', 'success', this.$t('Nota creada correctamente'))
            EventBus.$emit('inbox:closeOtherActions')
          },
          () => {
            EventBus.$emit('showAlert', 'danger', this.$t('Hubo un problema al intentar crear la nota'))
          },
        )
        .finally(() => {
          this.contactNote.loading = false
        })
    },
    saveNote () {
      this.contactNote.loading = true
      ContactNoteService.save({
        id: this.contactNote.id,
        annotation: this.contactNote.annotation,
        contact_id: this.contactNote.contact_id,
      })
        .then(
          () => {
            this.addNoteEditedMessage(this.contactNote.annotation)
            this.getNotes()
            this.contactNote.annotation = ''
            this.contactNote.show = false
            EventBus.$emit('showAlert', 'success', this.$t('Nota actualizada correctamente'))
            EventBus.$emit('inbox:closeOtherActions')
          },
          () => {
            EventBus.$emit('showAlert', 'danger', this.$t('Hubo un problema al intentar actualizar la nota'))
          },
        )
        .finally(() => {
          this.contactNote.loading = false
        })
    },
    deleteNote (item) {
      ContactNoteService.delete({
        id: item.id,
      })
        .then(
          () => {
            this.getNotes()
            this.contactNote.annotation = ''
            this.contactNote.show = false
            EventBus.$emit('showAlert', 'success', this.$t('Nota eliminada correctamente'))
            EventBus.$emit('inbox:closeOtherActions')
          },
          () => {
            EventBus.$emit('showAlert', 'danger', this.$t('Hubo un problema al intentar eliminar la nota'))
          },
        )
    },
    getCurrentUserRoutes () {
      RoutesService.getCurrentUserRoutes()
      .then((routes) => {
        if (routes.length) {
          this.currentUserRoutes = routes
          this.routes.list = routes
          this.currentUserMsisdn = this.currentUserRoutes[0].msisdn
        }
      })
    },
    modifyBlacklistForRoomInAction (blocklistTypes) {
      this.modifyBlacklist(this.roomInAction, blocklistTypes)
    },
    modifyBlacklist (room, blocklistTypes) {
      let toAdd, toRemove

      if (room.blocklist_status) {
        toAdd = blocklistTypes.filter(e => !room.blocklist_status.includes(e))
        toRemove = room.blocklist_status.filter(e => !blocklistTypes.includes(e))
      } else {
        toAdd = blocklistTypes
        toRemove = []
      }
      if (toAdd.length) {
        EventBus.$emit('hideAlert')
        SessionsService.addContactToBlackList({
          msisdn_user: this.currentUserMsisdn,
          msisdn_client: room.users[1].contact.msisdnClient,
          blocklistTypes: toAdd,
        }).then((res) => {
          toAdd.forEach(blockType => {
            this.addBlockedMessage(1, blockType)
            this.currentRoom.lastMessage.lastAction = 'blocked'
            this.currentRoom.lastMessage.blockStatus = 1
            this.currentRoom.lastMessage.blockType = blockType
          })
          this.roomsSource.forEach((roomElement, index) => {
            if (roomElement.roomId === room.roomId) {
              if (roomElement.blocklist_status) {
                roomElement.blocklist_status.push(...toAdd)
              } else {
                roomElement.blocklist_status = toAdd
              }
              this.roomsSource[index] = roomElement
            }
          })

          if (room.blocklist_status.includes(BlocklistType.INBOX_ID)) {
            EventBus.$emit('Whatsapp:disableSendMessage')
            EventBus.$emit('Whatsapp:hideQuickResponses')
          }
          this.filterChats()
          EventBus.$emit('showAlert', 'success', this.$t('Se han guardado los cambios').toString())
        })
      }

      if (toRemove.length) {
        EventBus.$emit('hideAlert')
        SessionsService.removeContactFromBlackList({
          msisdn_user: this.currentUserMsisdn,
          msisdn_client: room.users[1].contact.msisdnClient,
          blocklistTypes: toRemove,
        }).then((res) => {
          toRemove.forEach(blockType => {
            this.addBlockedMessage(0, blockType)
            this.currentRoom.lastMessage.lastAction = 'blocked'
            this.currentRoom.lastMessage.blockStatus = 0
            this.currentRoom.lastMessage.blockType = blockType
          })
          this.roomsSource.forEach((roomElement, index) => {
            if (roomElement.roomId === room.roomId) {
              toRemove.forEach(e => {
                const index = roomElement.blocklist_status?.findIndex(v => v === e)
                roomElement.blocklist_status?.splice(index, 1)
              })
              this.roomsSource[index] = roomElement
            }
          })

          if (!room.blocklist_status.includes(4)) {
            EventBus.$emit('Whatsapp:enableSendMessage')
            EventBus.$emit('Whatsapp:showQuickResponses')
          }
          this.filterChats()
          EventBus.$emit('showAlert', 'success', this.$t('Se han guardado los cambios').toString())
        })
      }
    },
    toggleAsRead () {
      if (this.isContactUnread(this.currentRoom)) {
        this.currentRoom && this.markAsRead({ room: this.currentRoom })
      } else {
        this.currentRoom && this.markAsUnread({ room: this.currentRoom })
      }
    },
    markAsRead ({ room }) {
      ContactsService.markAsRead({ id: room.users[1].contact.id })
      .then(result => {
        room.users[1].contact.read = 1
        room.status = {
          opened: !room.status.closed,
          closed: room.status.closed,
          pending: false,
        }
      })
    },
    markAsUnread ({ room }) {
      ContactsService.markAsUnread({ id: room.users[1].contact.id })
      .then(result => {
        room.users[1].contact.read = 0
        room.status = {
          opened: false,
          closed: room.status.closed,
          pending: !room.status.closed,
        }
      })
    },
    closeConversationFromRoomInAction (closeReason) {
      const data = { room: this.roomInAction, closeReason: closeReason }
      this.closeConversation(data)
    },
    closeConversation (data) {
      const room = data.room
      const closeReason = data.closeReason
      SessionsService.closeConversation({ id: room.users[1].contact.id, reason: closeReason.name }).then((res) => {
        this.roomsSource = this.roomsSource.map(roomElement => {
          if (roomElement.roomId === room.roomId) {
            roomElement.status.closed = true
            roomElement.status.opened = false
            roomElement.status.pending = false
            roomElement.lastMessage.status = 0
            roomElement.lastMessage.content = closeReason.name
            roomElement.lastMessage.lastAction = 'close'
            roomElement.unreadCount = 0
          }
          return roomElement
        })
        room.users[1].contact.status = 0
        this.filterChats()
        this.addClosedMessage(closeReason)
        this.dialogConfirmCloseConversation = false
      }, () => {
        this.dialogConfirmCloseConversation = false
      })
    },
    openConversation (data) {
      const room = data.room
        this.roomsSource = this.roomsSource.map(roomElement => {
          if (roomElement.roomId === room.roomId) {
            roomElement.status.closed = false
            roomElement.status.opened = true
            roomElement.status.pending = false
            roomElement.lastMessage.status = 1
            roomElement.lastMessage.content = this.$t('Abierto')
            roomElement.lastMessage.lastAction = 'open'
            roomElement.unreadCount = roomElement.qty_pending_messages
          }
          return roomElement
        })
        room.users[1].contact.status = 1
        this.filterChats()
        this.addOpenedMessage({ name: this.$t('Abierto') })
      // })
    },
    openClosedConversation (data) {
      const room = data.room
      SessionsService.openConversation({ id: room.users[1].contact.id }).then((res) => {
        this.roomsSource = this.roomsSource.map(roomElement => {
          if (roomElement.roomId === room.roomId) {
            roomElement.status.closed = false
            roomElement.status.opened = true
            roomElement.status.pending = false
            roomElement.lastMessage.status = 1
            roomElement.lastMessage.content = this.$t('Abierto')
            roomElement.lastMessage.lastAction = 'open'
            roomElement.unreadCount = roomElement.qty_pending_messages
          }
          return roomElement
        })
        room.users[1].contact.status = 1
        this.filterChats()
        this.addOpenedMessage({ name: this.$t('Abierto') })
      })
    },
    filterChats (filters) {
      this.roomFilters = filters || this.roomFilters
      this.allRooms = this.roomsSource
      if (this.roomFilters.opened === false && this.roomFilters.closed === false && this.roomFilters.pending === false) {
        this.rooms = this.allRooms
        return
      }

      let preRooms = this.allRooms.filter(room => {
        const isOpenedOrPending = (room.status.opened === this.roomFilters.opened || room.status.pending === this.roomFilters.opened)
        const isClosed = room.status.closed === this.roomFilters.closed
        const isPending = room.status.pending === this.roomFilters.pending
        if (this.roomFilters.opened) {
          return isOpenedOrPending
        } else if (this.roomFilters.closed) {
          return isClosed
        } else if (this.roomFilters.pending) {
          return isPending
        } else {
          return false
        }
      })

      if (this.focusedRoom !== undefined) {
        EventBus.$emit('showLoading', true)
        preRooms = preRooms.filter((room) => {
          return room.roomId !== this.focusedRoom.roomId
        })

        if (preRooms.length === 0) {
          this.rooms = [this.focusedRoom]
        } else {
          this.rooms = [...[this.focusedRoom], ...preRooms]
        }

        setTimeout(() => {
          EventBus.$emit('Whatsapp:openRoom', this.focusedRoom)
        })
      }

      // this.messages = []
    },
    randomNumber () {
      return Math.floor(Math.random() * 1000)
    },
    loadMessages (data, offset = 0) {
      if (!this.currentUserMsisdn && !this.contactMsisdn) {
        return
      }

      EventBus.$emit('Whatsapp:hideQuickResponses')
      this.currentRoom = data.room
      this.contactMsisdn = this.currentRoom.users[1].msisdnClient
      this.messages = []
      this.roomsLoaded = false
      this.messagesLoaded = false
      // if (!this.currentRoom.messages) {
        SessionsService.getMessages({
          msisdnUser: this.currentUserMsisdn,
          msisdnClient: this.contactMsisdn,
          offset,
          baseMessageId: this.task ? this.task.base_message_id : undefined,
        })
        .then((res) => {
          const preMessages = []
          res.messages.forEach(element => {
            const message = this.loadMessage(element)

            if (message) {
              preMessages.push(message)
            }
          })

          this.messages = preMessages

          // Add reactions
          res.messages.filter(m => m.reaction_message !== null && m.response_id).forEach(r => {
            this.addReactionToMessage(r)
          })

          this.roomsLoaded = true
          this.messagesLoaded = true
          this.currentRoom.messages = this.messages
          this.currentRoom.offset = parseInt(res.offset)

          SessionsService.getSessionAgentFromMsisdns({ msisdnUser: this.currentUserMsisdn, msisdnClient: this.contactMsisdn }).then((res) => {
            this.chatProps.currentAgent.loadData(res)
            EventBus.$emit('last-session-agent-conversation')
          })

          this.getNotes()

          if (this.task) {
            setTimeout(() => {
              EventBus.$emit('showLoading', true)
              localStorage.removeItem('load-conversation-from-task')
              EventBus.$emit('WhatsAppAgent::locateMessage', this.task)
              this.task = undefined
            })
          }
        })
      // } else {
      //   this.messages = this.currentRoom.messages
      //   this.roomsLoaded = true
      //   this.messagesLoaded = true
      // }
      if (this.currentRoom.blocklist_status?.includes(BlocklistType.INBOX_ID)) {
        EventBus.$emit('Whatsapp:disableSendMessage')
      } else {
        EventBus.$emit('Whatsapp:enableSendMessage')
        this.setLastCSWTime()
      }
    },
    loadMessagesNextPage (offset = 0) {
      this.messagesLoaded = false
      this.contactMsisdn = this.currentRoom.users[1].msisdnClient
      SessionsService.getMessages({
        msisdnUser: this.currentUserMsisdn,
        msisdnClient: this.contactMsisdn,
        offset: this.currentRoom.offset + this.MESSAGES_PER_LOAD,
      })
      .then((res) => {
        const preMessages = []

        res.messages.forEach(element => {
          const message = this.loadMessage(element)

          if (message) {
            preMessages.push(message)
          }
        })

        this.messages = [
          ...preMessages,
          ...this.messages,
        ]
        this.currentRoom.messages = this.messages
        this.currentRoom.offset += this.MESSAGES_PER_LOAD
        this.setScrollTop()
        this.messagesLoaded = true
      })
    },
    setScrollTop () {
      this.$el.querySelector('#messages-list').scrollTop = 25
    },
    loadRoomsNextPage (roomsLoading) {
      this.roomsLoaded = false
      this.loadingRooms = true
      if (roomsLoading.opened || roomsLoading.pending) {
        const lastConversation = this.allRooms.reduce((prev, curr) => {
          if (curr.status.closed) return prev
          return moment(prev.updatedAt).isSameOrBefore(moment(curr.updatedAt)) ? prev : curr
        })
        if (lastConversation) {
          SessionsService.getConversationsOpenPending({
            msisdn: this.currentUserMsisdn,
            toDate: lastConversation.updatedAt,
          }).then((conversations) => {
            this.addNewConversations(conversations)
            this.loadingRooms = false
            this.roomsLoaded = true

            this.filterChats()
          })
        }
      } else {
        const lastConversation = this.allRooms.reduce((prev, curr) => {
          if (!curr.status.closed) return prev
          return moment(prev.updatedAt).isSameOrBefore(moment(curr.updatedAt)) ? prev : curr
        })
        if (lastConversation) {
          SessionsService.getConversationsClosed({
            msisdn: this.currentUserMsisdn,
            toDate: lastConversation.updatedAt,
          }).then((conversations) => {
            this.addNewConversations(conversations)
            this.loadingRooms = false
            this.roomsLoaded = true

            this.filterChats()
          })
        }
      }
    },
    setLastSessionTime () {
      SessionsService.getLastestSessionExpirationDate({
        msisdnUser: this.currentUserMsisdn,
        msisdnClient: this.currentRoom.users[1].msisdnClient,
      }).then((res) => {
        const date = this.$options.filters.dateLocal(res.expiration_at, 'YYYY-MM-DD HH:mm:ss', this.user.userTimezone)
        EventBus.$emit('loadSessionTime', date ?? moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'))
      })
    },
    setLastCSWTime () {
      SessionsService.getLastestCSWExpirationDate({
        msisdnUser: this.currentUserMsisdn,
        msisdnClient: this.currentRoom.users[1].msisdnClient,
      }).then((res) => {
        if (!res.expiration_at) {
          EventBus.$emit('loadCSWTime', null)
        } else {
          const date = this.$options.filters.dateLocal(res.expiration_at, 'YYYY-MM-DD HH:mm:ss', this.user.userTimezone)
          EventBus.$emit('loadCSWTime', date ?? moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'))
        }
      })
    },
    // eslint-disable-next-line complexity
    loadMessage (element, read = true) {
      let message = null

      switch (element.type) {
        case 'reaction':
          return message
        case 'closed':
          message = new ClosedMessage(element)
          break
        case 'opened':
          message = new OpenedMessage(element)
          break
        case 'contact-edition':
          message = new ContactEditionMessage(element)
          break
        case 'contact-block':
          message = new ContactBlockMessage(element)
          break
        case 'contact-note':
          message = new ContactNoteMessage(element)
          break
        case 'change-agent':
          message = new ChangeAgentMessage(element)
          break
        case 'text':
          message = new TextMessage(element)
          break
        case 'button':
          message = new ButtonMessage(element)
          break
        case 'location':
          message = new LocationMessage(element)
          break
        case 'unsupported':
          message = new UnsupportedMessage(element)
          break
        case 'interactive':
          if (element.response_id) {
            message = new InteractiveAnswerMessage(element)
          } else {
            message = new InteractiveMessage(element)
          }
          break
        case 'template':
          let headerValue = ''
          const bodyValue = []
          let buttonValue = ''
          if (element.template_message_header) {
            // eslint-disable-next-line max-depth
            switch (element.template_message_header.type) {
              case 'text':
                headerValue = element.template_message_header?.text_parameter?.text
                break
              case 'image':
              case 'video':
              case 'audio':
              case 'document':
                headerValue = {
                  url: element.template_message_header?.multimedia_parameter?.link,
                  mimeType: element.template_message_header?.multimedia_parameter?.mime_type,
                  id_file: element.template_message_header?.multimedia_parameter?.file_id,
                }
                break
              case 'location':
                const locationData = element.template_message_header?.location_parameter
                // eslint-disable-next-line max-depth
                if (locationData) {
                  headerValue = {
                    businessName: locationData.name,
                    businessAddress: locationData.address,
                    latitude: locationData.latitude,
                    longitude: locationData.longitude,
                  }
                }
                break
            }
          }

          element.template_message_body.forEach(bodyElement => {
            switch (bodyElement.type) {
              case 'text':
                bodyValue.push(bodyElement.text_parameter.text)
                break
            }
          })


          if (element.template_message_button && element.template_message_button.length > 0) {
            // eslint-disable-next-line max-depth
            if (element.template_message_button[0].sub_type === 'url') {
              buttonValue = element.template_message_button[0].text
            }
          }
          message = new TemplateMessage(element)
          message.setSubstitutions(this.selectedUserRoute?.providerName, { header: headerValue, body: bodyValue, buttons: buttonValue })
          message = this.getFormattedTemplateProperties(message, message.template, { substitutions: message.substitutions })


          break
        default:
          if (['image', 'video', 'document', 'audio'].includes(element.type)) {
            message = new MultimediaMessage(this.selectedUserRoute.providerName, element)
          }
          break
      }

      if (message) {
        // eslint-disable-next-line no-nested-ternary
        const username = element.msisdn !== this.currentUserMsisdn ? (element.contact ? element.contact.firstName + ' ' + element.contact.lastName : '') : 'Yo'
        message.username = username

        if (element.status_message?.send_at) {
          message.setToSent()
        }
        if (element.status_message?.delivered_at) {
          message.setToDelivered()
        }

        if (element.status_message?.read_at) {
          message.setToRead()
        } else if (element.msisdn !== this.currentUserMsisdn && this.isRealMessage(element.type)) {
          WhatsappMessageService.setProvider(this.selectedUserRoute.providerName)
          WhatsappMessageService.setRead({ message_id: message.indexId, msisdn: this.currentUserMsisdn })
          .then(response => {
            message.setToRead()
          })
        }
      }

      return message
    },
    addReactionToMessage (reaction) {
      const foundMsg = this.messages.find(m => m._id === reaction.response_id)
      if (foundMsg) {
        foundMsg.reactions = foundMsg.reactions ?? {}
        if (reaction.reaction_message.emoji) {
          // eslint-disable-next-line max-depth
          if (foundMsg.reactions[reaction.reaction_message.emoji]) {
            // eslint-disable-next-line max-depth
            if (!foundMsg.reactions[reaction.reaction_message.emoji].includes(reaction.msisdn)) {
              foundMsg.reactions[reaction.reaction_message.emoji].push(reaction.msisdn)
            }
          } else {
            foundMsg.reactions[reaction.reaction_message.emoji] = [reaction.msisdn]
          }
        }
      }
    },
    isRealMessage (type) {
      return ![
        'closed',
        'opened',
        'contact-edition',
        'contact-block',
        'change-agent',
        'contact-note',
      ].includes(type)
    },
    getNewCurrentCommonMessage () {
      // generar ids para cada mensaje en common message
      const user = JSON.parse(localStorage.getItem('user'))
      const commonMessage = new CommonMessage()
      commonMessage.userId = user.id
      commonMessage.msisdnFrom = this.currentUserMsisdn
      commonMessage.msisdnTo = this.contactMsisdn
      return commonMessage
    },

    addAgentToMessages (messages) {
      let agent = this.chatProps.currentAgent
      if (this.chatProps.currentAgent.typeId === 1) {
        agent = this.chatProps.agents[0]
        EventBus.$emit('WhatsAppAgent::changeToUser')
      }
      return messages.map(message => {
        message.agent = agent
        return message
      })
    },

    // eslint-disable-next-line complexity
    async sendMessage (data) {
      const files = []
      if (data.files && data.files.length > 0) {
        data.files.forEach(currentFile => {
          files.push({
            name: currentFile.name,
            size: currentFile.size,
            mimeType: currentFile.type,
            type: currentFile.simpleType,
            url: currentFile.localUrl,
            extension: currentFile.extension,
          })
        })
      }

      let messageType = 'text'

      if (files.length > 0) {
        messageType = 'multimedia'
      }

      let commonMessage = CommonMessage.getNewCurrentCommonMessage(this.currentUserMsisdn, this.contactMsisdn)
      let messages = []

      switch (messageType) {
        case 'text':
          messages.push(new SendTextMessage(commonMessage, data.content))
          break
        case 'multimedia':
          files.forEach((file, index) => {
            if (['image', 'video', 'document', 'audio'].includes(file.type)) {
              messageType = file.type
              const content = index === (files.length - 1) ? data.content : ''
              commonMessage = CommonMessage.getNewCurrentCommonMessage(this.currentUserMsisdn, this.contactMsisdn)
              messages.push(new SendMultimediaMessage(
                commonMessage,
                file.name,
                file.extension,
                file.size,
                file.type,
                file.mimeType,
                file.url,
                '',
                content,
              ))
            }
          })
          break
      }


      this.currentRoom.lastMessage = {
        timestamp: this.$options.filters.dateLocal(moment(), 'YYYY-MM-DD HH:mm:ss', this.user.userTimezone),
        content: data.content,
        type: messageType,
        status: this.currentRoom.users[1].status,
        lastAction: 'message',
      }

      this.updateConversationLastMessageDate(this.currentRoom, this.currentRoom.lastMessage.timestamp)

      messages = this.addAgentToMessages(messages)

      SessionsService.sendMessage({ messages: messages })
      .then((res) => {
        // eslint-disable-next-line complexity
        messages.forEach(message => {
          let newMessage = null

          switch (message.type) {
            case 'text':
              newMessage = TextMessage.createFromSendTextMessage(message)
              break
            case 'multimedia':
            case 'image':
            case 'video':
            case 'document':
            case 'audio':
              if (['image', 'video', 'document', 'audio'].includes(message.simpleType)) {
                newMessage = MultimediaMessage.createFromSendMultimediaMessage(this.selectedUserRoute.providerName, message)
              }
              break
            case 'unsupported':
              newMessage = UnsupportedMessage.createFromSendUnsupportedMessage(message)
              break
          }

          if (newMessage) {
            newMessage.username = this.currentRoom.users[1].username
            this.addMessage(newMessage)
            this.currentRoom.unreadCount = 0
            this.setContactRead(this.currentRoom)
          }

          // Do not open conversation when user sends messages
          // if (this.currentRoom.users[1].contact.status === 0) {
          //   this.openConversation({ room: this.currentRoom })
          // }

          this.currentRoom.messagesNumberUser++
        })
      })
    },
    closeInteractiveDialog () {
      this.messageInteractiveDialog = false
      this.modelMessageInteractive = new ModelMessageInteractive()
    },
    sendMessageInteractive () {
      const commonMessage = CommonMessage.getNewCurrentCommonMessage(this.currentUserMsisdn, this.contactMsisdn)
      const messages = [new SendInteractiveMessage(commonMessage, this.modelMessageInteractive)]
      SessionsService.sendMessage({ messages })
      .then((res) => {
        EventBus.$emit('showAlert', 'success', this.$t('Mensaje enviado correctamente'))
        this.closeInteractiveDialog()
      })
    },
    sendTemplateMessage () {
      this.showReplaceVarsDialog = false
      let messages = [this.sendTemplateMessageObject]
      messages = this.addAgentToMessages(messages)
      SessionsService.sendMessage({ messages: messages })
      .then((res) => {
        messages.forEach(message => {
          let newMessage = TemplateMessage.createFromSendTemplateMessage(this.selectedUserRoute.providerName, message)
          newMessage.template = this.selectedTemplate
          newMessage = this.getFormattedTemplateProperties(newMessage, newMessage.template, this.sendTemplateMessageObject)
          if (newMessage) {
            this.addMessage(newMessage)
            this.currentRoom.unreadCount = 0
            this.setContactRead(this.currentRoom)
            this.sendTemplateMessageObject = new SendTemplateMessage()

            if (this.currentRoom.messagesNumberClient && (this.currentRoom.messagesNumberClient > 0 || this.currentRoom.isOpenCSW === 1)) {
              EventBus.$emit('hideSessionTimeExpiredAlert')
              EventBus.$emit('Whatsapp:enableSendMessage')
            }
            EventBus.$emit('Whatsapp:hideQuickResponses')

            this.currentRoom.lastMessage = {
              timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
              content: '',
              type: 'template',
              status: this.currentRoom.users[1].status,
            }

            // Do not open conversation when user sends messages
            // if (this.currentRoom.users[1].contact.status === 0) {
            //   this.openConversation({ room: this.currentRoom })
            // }
            this.currentRoom.messagesNumberUser++
          }
        })
        EventBus.$emit('templateMessage:recentlySendTemplate', true)
      })
    },
    getFormattedTemplateProperties (templateMessageObject, template, sendTemplateMessageObjectHelper) {
      if (template.head_board_option !== HeadboardOption.options.LOCATION) {
        templateMessageObject.headerSusbtitutedMessage = this.getCleanHeaderTextMessageSubstitution(true, template, sendTemplateMessageObjectHelper)
      }

      if (template.head_board_option === HeadboardOption.options.LOCATION) {
        templateMessageObject.name = sendTemplateMessageObjectHelper.substitutions.header.businessName
        templateMessageObject.address = sendTemplateMessageObjectHelper.substitutions.header.businessAddress
        templateMessageObject.latitude = sendTemplateMessageObjectHelper.substitutions.header.latitude
        templateMessageObject.longitude = sendTemplateMessageObjectHelper.substitutions.header.longitude
      }

      templateMessageObject.susbtitutedMessage = this.getCleanMessageSubstitutions(true, template, sendTemplateMessageObjectHelper)
      const callToActionResponses = template?.call_to_action_responses
        ? JSON.parse(template.call_to_action_responses)
        : []
      const callToActionUrlIndex = callToActionResponses?.findIndex(callToAction => callToAction.type === CALL_TO_ACTION_TYPE.EXTERNAL_LINK)
      if (callToActionUrlIndex !== -1) {
        templateMessageObject.callToActionUrlSubstituted = this.getCleanCallToActionUrlHelper(callToActionUrlIndex, true, template, sendTemplateMessageObjectHelper)
      } else {
        templateMessageObject.callToActionUrlSubstituted = ''
      }
      const callToActionCodeIndex = callToActionResponses.findIndex(callToAction => callToAction.type === CALL_TO_ACTION_TYPE.COPY_CODE)
      if (callToActionCodeIndex !== -1) {
        templateMessageObject.callToActionCodeSubstituted = this.getCleanCallToActionUrlHelper(callToActionCodeIndex, true, template, sendTemplateMessageObjectHelper)
      } else {
        templateMessageObject.callToActionCodeSubstituted = ''
      }

      templateMessageObject.username = this.currentRoom.users[1].username
      return templateMessageObject
    },
    sendLocationMessage () {
      let messages = [this.sendLocationMessageObject]
      messages = this.addAgentToMessages(messages)
      SessionsService.sendMessage({ messages: messages })
      .then((res) => {
        messages.forEach(message => {
          const newMessage = LocationMessage.createFromSendLocationMessage(message)
          newMessage.username = this.currentRoom.users[1].username
          this.addMessage(newMessage)
          this.currentRoom.unreadCount = 0
          this.setContactRead(this.currentRoom)
          this.locationDialog = false
          this.sendLocationMessageObject = new SendLocationMessage()

          this.currentRoom.lastMessage = {
            timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
            content: '',
            type: 'location',
            status: this.currentRoom.users[1].status,
          }
        })
      })
    },

    getConversationsClosed (msisdn = null) {
      this.currentUserMsisdn = msisdn || this.currentUserMsisdn
      this.roomsLoaded = false
      this.loadingRooms = true
      EventBus.$emit('showLoading', true, this.$t('Cargando datos...'))
      this.getConversationsClosedProcess(this.currentUserMsisdn)
    },

    getConversationsClosedProcess (msisdn, dateLastMessage = null) {
      SessionsService.getConversationsClosed({ msisdn: msisdn, dateLastMessage: dateLastMessage }).then((conversations) => {
        this.addNewConversations(conversations)
        this.filterChats()
        if (conversations.length !== 0) {
          dateLastMessage = moment(conversations[conversations.length - 1].date_last_message).format('YYYY-MM-DD HH:mm:ss')
          this.getConversationsClosedProcess(msisdn, dateLastMessage)
        } else {
          this.loadingRooms = false
          this.roomsLoaded = true
        }
        EventBus.$emit('showLoading', false)
      })
    },

    getRooms (msisdn = null) {
      this.currentUserMsisdn = msisdn || this.currentUserMsisdn
      this.roomsLoaded = false
      this.loadingRooms = true
      EventBus.$emit('showLoading', true, this.$t('Cargando datos...'))
      this.roomsSource = []
      this.getRoomsProcess(this.currentUserMsisdn)
    },

    getRoomsProcess (msisdn, dateLastMessage = null) {
      SessionsService.getConversations({ msisdn: msisdn, dateLastMessage: dateLastMessage }).then((conversations) => {
        this.addNewConversations(conversations)
        this.filterChats()
        if (conversations.length !== 0) {
          dateLastMessage = moment(conversations[conversations.length - 1].date_last_message).format('YYYY-MM-DD HH:mm:ss')
          this.getRoomsProcess(msisdn, dateLastMessage)
        } else {
          this.loadingRooms = false
          this.roomsLoaded = true
        }
        EventBus.$emit('showLoading', false)
      })

      // this.loadingRooms = false
      // this.roomsLoaded = true
      // EventBus.$emit('showLoading', false)
    },

    // eslint-disable-next-line complexity
    processConversation (conversation) {
      if (!conversation.contact) return false

      const lastMessageData = conversation?.lastMessage
      let content = lastMessageData?.type === 'text' ? lastMessageData.text_message?.body?.replace(TextMessage.REPLACE_REGEX, ' ') : ''

      const isLastMessageOpenConversation = conversation.lastMessage?.action === 'open'
      const isLastMessageContactEdition = conversation.lastMessage?.action === 'edit'

      const isRead = conversation.contact.read === 1
      const isStatusFour = conversation.blocklist_status && conversation.blocklist_status.length > 0 ? conversation.blocklist_status[conversation.blocklist_status.length - 1] === 4 : false
      const isClosedConversation = isStatusFour || conversation.contact.status === 0
      const isOpened = isClosedConversation ? false : isRead
      const isPending = isClosedConversation ? false : !isRead

      if (isClosedConversation) {
        content = conversation.contact.last_closed_reason?.reason
      } else if (isLastMessageContactEdition) {
        content = this.$t('Editado')
      } else if (isLastMessageOpenConversation) {
        content = this.$t('Abierto')
      }

      let lastMessage = {}
      if (lastMessageData) {
        lastMessage = {
          timestamp: conversation.date_last_message,
          content: content,
          agentId: lastMessageData.agentId,
          agentTypeId: lastMessageData.agentTypeId,
          agent: lastMessageData.agent,
          type: lastMessageData?.type ?? 'text',
          lastAction: conversation.lastMessage?.action,
          status: conversation.contact?.status ?? 1,
          blockStatus: lastMessageData.blockStatus,
          blockType: lastMessageData.blockType,
        }
      } else {
        lastMessage = {
          timestamp: conversation.created_at,
          content: this.$t('Creado'),
          type: 'text',
          lastAction: 'edit',
          status: 1,
        }
      }

      const conversationObj = {
        roomId: conversation.id,
        roomName: conversation.contact ? (conversation.contact.firstname ?? '') + ' ' + (conversation.contact.lastname ?? '') : 'ex',
        avatar: null,
        index: conversation.id,
        newContact: conversation.new_contact,
        blocklist_status: conversation.blocklist_status ? conversation.blocklist_status : [],
        dateLastMessage: conversation.date_last_message,
        messagesNumberClient: conversation.last_session_messages_client,
        messagesNumberUser: conversation.last_session_messages_user,
        isOpenCSW: conversation.is_open,
        cswExpirationDate: conversation.csw_expiration_date,
        status: {
          opened: isOpened,
          closed: isClosedConversation,
          pending: isPending,
        },
        unreadCount: conversation.qty_pending_messages, // isPending ? conversation.qty_pending_messages : 0,
        pendingMessages: conversation.qty_pending_messages,
        lastMessage: lastMessage,
        contactOnSendings: conversation.contact_on_sendings,
        updatedAt: conversation.updated_at,
        foundOnSearch: [],
        // lastMessage: {
        //   _id: 'xyz',
        //   content: 'texto del último mensaje',
        //   senderId: '1234',
        //   username: 'John Doe',
        //   timestamp: '10:20',
        //   saved: true,
        //   distributed: false,
        //   seen: false,
        //   new: true,
        // },
        users: [
          {
            _id: this.currentUserMsisdn,
            username: 'John Doe',
            avatar: 'https://picsum.photos/200',
            status: {
              state: 'online',
              lastChanged: 'today, 14:30',
            },
          },
          {
            _id: conversation.msisdn_client,
            username: conversation.contact ? conversation.contact.firstname + ' ' + conversation.contact.lastname : '',
            msisdnClient: conversation.msisdn_client,
            avatar: 'https://picsum.photos/200',
            status: {
              state: 'online',
              lastChanged: '14 July, 20:00',
            },
            contact: new Contact(conversation.contact),
          },
        ],
        typingUsers: [4321],
      }

      return conversationObj
    },
    addNewConversations (conversations) {
      conversations.forEach(conversation => {
        const conversationObj = this.processConversation(conversation)
        if (conversationObj) {
          this.roomsSource.push(conversationObj)
        }
      })
      this.rooms = this.roomsSource
    },
    addClosedMessage (closeReason) {
      const closedReasonData = {
        id: uuidv4(),
        reason: closeReason.name,
        created_at: new Date(),
        msisdn: this.contactMsisdn,
        type: 'closed',
      }
      const closedMessage = this.loadMessage(closedReasonData)
      const messages = this.messages
      messages.push(closedMessage)
      this.messages = messages
    },
    addOpenedMessage (openedReason) {
      const openedData = {
        id: uuidv4(),
        reason: openedReason.name,
        created_at: new Date(),
        msisdn: this.contactMsisdn,
        type: 'opened',
      }
      const openedMessage = this.loadMessage(openedData)
      const messages = this.messages
      messages.push(openedMessage)
      this.messages = messages
    },
    addNoteEditedMessage (content) {
      const contactNoteData = {
        id: uuidv4(),
        user: this.user.username,
        created_at: new Date(),
        msisdn: this.contactMsisdn,
        type: 'contact-note',
        content: content,
      }
      const contactNoteMessage = this.loadMessage(contactNoteData)
      const messages = this.messages
      messages.push(contactNoteMessage)
      this.messages = messages
    },
    addContactEditedMessage () {
      const contactEditionData = {
        id: uuidv4(),
        user: this.user.username,
        created_at: new Date(),
        msisdn: this.contactMsisdn,
        type: 'contact-edition',
      }
      const contactEditionMessage = this.loadMessage(contactEditionData)
      const messages = this.messages
      messages.push(contactEditionMessage)
      this.messages = messages
    },
    addBlockedMessage (status, blockType) {
      const blockedMessageData = {
        id: uuidv4(),
        user: this.user.username,
        created_at: new Date(),
        msisdn: this.contactMsisdn,
        type: 'contact-block',
        status: status,
        block_type: blockType,
      }
      const contactEditionMessage = this.loadMessage(blockedMessageData)
      const messages = this.messages
      messages.push(contactEditionMessage)
      this.messages = messages
    },
    addChangeAgentMessage (agent) {
      this.chatProps.currentAgent = agent
      const changeAgentData = {
        id: uuidv4(),
        user: this.user.username,
        created_at: new Date(),
        agent_id: this.chatProps.currentAgent.id,
        agent_type_id: this.chatProps.currentAgent.typeId,
        agent_name: this.chatProps.currentAgent.name,
        msisdn: this.contactMsisdn,
        type: 'change-agent',
      }
      const contactEditionMessage = this.loadMessage(changeAgentData)
      const messages = this.messages
      messages.push(contactEditionMessage)
      this.messages = messages

      this.currentRoom.lastMessage.agent = this.chatProps.currentAgent.name
      this.currentRoom.lastMessage.agentId = this.chatProps.currentAgent.id
      this.currentRoom.lastMessage.agentTypeId = this.chatProps.currentAgent.typeId
      this.currentRoom.lastMessage.type = 'change-agent'
      this.currentRoom.lastMessage.action = 'change-agent'
    },
  },
}
