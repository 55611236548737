import ContactService from '@/services/web-chat/contact.service.js'
import { Contact } from '@/models/web-chat/Contact.js'
import CountryService from '@/services/country.service.js'
import SelectCountry from '@/components/ContactSource/SelectCountry/SelectCountry.vue'
import ChatTagService from '@/services/web-chat/chat-tag.service'

export default {
    name: 'Contacts',
    components: {
        SelectCountry,
    },
    props: {
        dialogCtrl: {
            type: Object,
            default: () => {
                return { show: false }
            },
        },
        contact: {
            type: Object,
            default: () => {
                return new Contact()
            },
        },
    },
    data: function () {
        return {
            countries: [],
            tags: [],
            searchTagText: '',
        }
    },
    watch: {

    },
    methods: {
        removeTag (item) {
            this.contact.tags = this.contact.tags.filter(tagId => { return tagId !== item.id })
        },
        changedTag () {
            this.searchTagText = ''
        },
        allTags () {
            ChatTagService.allTags().then((response) => {
                this.tags = response
            })
        },
        closeContactDrawer () {
            this.dialogCtrl.show = false
            this.$emit('close')
        },
        getAllCountries () {
            CountryService
                .getAllCountries()
                .then(
                    (response) => {
                        this.countries = response.map((country) => {
                            return {
                                value: country.iso_code_2,
                                text: `${country.name} +(${country.prefix})`,
                            }
                        })
                    },
                    () => {},
                )
        },

        saveContact () {
            ContactService.simpleSave(this.contact).then((contact) => {
                this.$emit('saved', contact)
                this.dialogCtrl.show = false
            })
        },


    },
    computed: {},
    created () {
        this.getAllCountries()
        this.allTags()
    },
}
